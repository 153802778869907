<template>
  <el-drawer title="抽盒详情" :visible.sync="isDrawer" :with-header="false" size="86%" :before-close="handleClose"
    :wrapperClosable="false" :close-on-press-escape="false" :modal-append-to-body="false">
    <div class="h-full flex flex-dc" v-if="isDrawer">
      <div class="drawer_title pall-30 flex flex-jb flex-n">
        <div class="fs-18 fw-bold">会员详情</div>
        <i class="el-icon-close fs-20 pointer" @click="handleClose"></i>
      </div>
      <div class="flex-1 overflow-scroll-y">
        <t-simple-form :ref-obj.sync="formOpts.ref" :formOpts="formOpts" :widthSize="2" @handleEvent="handleEvent"
          class="drawer_form">
          <template #editor>
            <div class="ml-40" style="border: 1px solid #ccc;">
              <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig"
                :mode="mode" />
              <Editor style="height: 150px; overflow-y: hidden;" v-model="formOpts.formData.note"
                :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" />
            </div>
          </template>
        </t-simple-form>
        <!-- 表格 -->
        <div class="pos-s t-0 bgc-fff zi-10">
          <div class="pb-20 pv-40 mr-40 flex flex-ac flex-jb">
            <div class="fs-14 fw-bold flex-1">
              <span class="h-10 w-5 bgc-38393b mr-10 dis-in-block"></span>
              <span>会员道具</span>
            </div>
            <div class="flex flex-ac">
              <div class="mr-30">数量：{{formOpts.formData.json.length}}</div>
              <el-button type="primary" @click="addDomain">新增</el-button>
            </div>
          </div>
        </div>
        <div class="pv-40 mr-40">
          <TableHeightFull>
            <EleTable ref="tableRef" slot="table" :tableData="formOpts.formData.json" :columns="columns" height="100%"
              :border="false" v-loading="tabLoading">
              <template v-slot:img="scope">
                <img :src="scope.row.propImg" alt="" class="tableImg">
              </template>
            </EleTable>
          </TableHeightFull>
        </div>
      </div>
      <div class="drawer_button pall-30 flex-n flex flex-ac flex-je">
        <el-button type="primary" @click="submitForm" :loading="butLoading">{{memberId?'保存':'提交'}}</el-button>
        <el-button type="primary" plain @click="handleClose">取消</el-button>
      </div>
    </div>
    <!-- 道具 -->
    <el-dialog :title="dialogTitle" :append-to-body="true" :visible.sync="dialogFormVisible" top="15vh" width="60%">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="flex flex-w">
        <el-form-item label="会员周期" prop="cycle" v-if="'cycle' in ruleForm" :style="{ width: `50%` }"
          :rules="{required: true, message: '会员周期不能为空', trigger: 'blur'}">
          <el-input v-model="ruleForm.cycle"></el-input>
        </el-form-item>
        <el-form-item label="赠送次数" prop="giveNum" v-if="'giveNum' in ruleForm" :style="{ width: `50%` }"
          :rules="{required: true, message: '赠送次数不能为空', trigger: 'blur'}">
          <el-input v-model="ruleForm.giveNum"></el-input>
        </el-form-item>
        <el-form-item label="道具名称" prop="propName" v-if="'propName' in ruleForm" :style="{ width: `50%` }"
          :rules="{required: true, message: '道具名称不能为空', trigger: 'blur'}">
          <el-input v-model="ruleForm.propName"></el-input>
        </el-form-item>
        <el-form-item label="道具类型" prop="propType" v-if="'propType' in ruleForm" :style="{ width: `50%` }"
          :rules="{required: true, message: '请至少选择一个道具卡类型', trigger: 'change'}">
          <el-select v-model="ruleForm.propType" placeholder="请选择" :style="{ width: `100%` }">
            <el-option v-for="item in propTypes" :key="item.value" :label="item.key" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="道具图片" prop="propImg" v-if="'propImg' in ruleForm" :style="{ width: `100%` }"
          :rules="{required: true, message: '图片不能为空', trigger: 'blur'}">
          <upload-file upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
            iconName="el-icon-upload" iconSize="26" v-model="ruleForm.propImg"></upload-file>
        </el-form-item>
        <el-form-item label="数量" prop="num" v-if="'num' in ruleForm" :style="{ width: `50%` }"
          :rules="{required: true, message: '道具数量不能为空', trigger: 'blur'}">
          <el-input type="number" v-model="ruleForm.num"></el-input>
        </el-form-item>
        <el-form-item label="有效天数" prop="validDay" v-if="'validDay' in ruleForm && ruleForm.propType != 1"
          :rules="{required: true, message: '有效天数不能为空', trigger: 'blur'}" :style="{ width: `50%` }">
          <el-input type="number" v-model="ruleForm.validDay"></el-input>
        </el-form-item>
        <el-form-item label="优惠券面额" prop="money" v-if="'money' in ruleForm && ruleForm.propType == 4"
          :rules="{required: true, message: '优惠券面额不能为空', trigger: 'blur'}" :style="{ width: `50%` }">
          <el-input type="number" v-model="ruleForm.money"></el-input>
        </el-form-item>
        <el-form-item label="优惠券类型" prop="couponsType" v-if="'couponsType' in ruleForm && ruleForm.propType == 4"
          :rules="{required: true, message: '请至少选择一个优惠劵类型', trigger: 'change'}" :style="{ width: `50%` }">
          <el-select v-model="ruleForm.couponsType" placeholder="请选择" :style="{ width: `100%` }">
            <el-option v-for="item in couponsTypes" :key="item.value" :label="item.key" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="使用门槛" prop="doorSill" v-if="'doorSill' in ruleForm && ruleForm.propType == 4"
          :rules="{required: true, message: '使用门槛不能为空', trigger: 'blur'}" :style="{ width: `50%` }">
          <el-input type="number" v-model="ruleForm.doorSill"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort" v-if="'sort' in ruleForm" :style="{ width: `50%` }">
          <el-input type="number" v-model="ruleForm.sort"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="affirmPopup" :loading="butLoading">{{dialogTitle == '新增'?'确 定':'保 存'}}
        </el-button>
      </div>
    </el-dialog>
  </el-drawer>
</template>

<script>
import { IEditorConfig } from '@wangeditor/editor'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { mapState } from 'vuex'

export default {
  name: 'richText',
  components: {
    Editor, Toolbar,
    "TSimpleForm": (resolve) => require(["@/components/public/TSimpleForm"], resolve),
    "UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
  },
  computed: {
    ...mapState('menu', {
      'propTypes': state => state.dictList ? state.dictList.prop_type : [],  //道具类型
      'couponsTypes': state => state.dictList ? state.dictList.coupons_type : [],  //优惠劵类型
    })
  },
  data () {
    return {
      memberId: '',//详情id
      isDrawer: false,
      tabLoading: false,
      butLoading: false,
      formOpts: {
        labelPosition: 'top',
        ref: null,
        formData: {
          id: '',
          memberName: '',//会员名称
          price: '',//原价
          soldPrice: '',//现价
          sort: '',//排序
          note: '',//备注
          json: [], //商品信息
        },
        fieldList: [
          { label: '会员名称', value: 'memberName', comp: 'el-input' },
          { label: '会员天数', value: 'dayNum', comp: 'el-input' },
          { label: '价值', value: 'price', type: 'number', comp: 'el-input' },
          { label: '价格', value: 'soldPrice', type: 'number', comp: 'el-input' },
          { label: '排序', value: 'sort', type: 'number', comp: 'el-input' },
          { label: '', slotName: 'editor', className: 't-form-block' },
          { label: '', slotName: 'json', className: 't-form-block' },
        ],
        rules: {
          memberName: [{ required: true, message: '请输入会员名称', trigger: 'blur' }],
          price: [{ required: true, message: '请输入价格', trigger: 'blur' }],
          sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
        },
        //按钮
        operatorList: [],
        // 相关列表
        listTypeInfo: {}
      },
      // 表格
      columns: [
        { label: '排序', prop: 'sort', align: 'left', width: '70' },
        { label: '周期', prop: 'cycle', align: 'left', width: '70' },
        { label: '赠送的次数', prop: 'giveNum', align: 'left', width: '100' },
        { columnType: 'custom', label: '道具图片', prop: 'img', align: 'left', width: '120' },
        { label: '道具名称', prop: 'propName', align: 'left', 'show-overflow-tooltip': true },
        {
          label: '道具类型', prop: 'propType', align: 'left', width: '120', formatData: (e) => {
            let ele = this.propTypes.find(ele => ele.value == e)
            return ele ? ele.key : e
          }
        },
        { label: '道具数量', prop: 'num', align: 'left', width: '100' },
        { label: '有效天数', prop: 'validDay', align: 'left', width: '100' },
        {
          label: '优惠劵类型', prop: 'couponsType', align: 'left', width: '150', formatData: (e) => {
            let ele = this.couponsTypes.find(ele => ele.value == e)
            return ele ? ele.key : e
          }
        },
        { label: '面额', prop: 'money', align: 'left', width: '70' },
        { label: '门槛', prop: 'doorSill', align: 'left', width: '70' },
        {
          columnType: 'button', label: '操作', align: 'right', fixed: 'right', width: '120',
          buttons: [
            { type: 'text', text: '编辑', fn: this.editList, },
            { type: 'text', text: '删除', fn: this.deleteList, }
          ],
        },
      ],
      //富文本
      editor: null,
      toolbarConfig: {},
      editorConfig: { placeholder: '请输入内容...' },
      mode: 'default', // or 'simple'
      //弹框
      dialogTitle: '新增',
      formIndex: '', //弹框index
      dialogFormVisible: false,
      ruleForm: {
        cycle: '7', // 周期,多少天送一次
        giveNum: '4', //赠送的次数
        propImg: '',//道具图片
        propName: '',//道具名称
        propType: '', //道具类型
        num: '', //道具数量
        validDay: '', //有效天数
        money: '', //优惠券面额
        doorSill: '', //使用门槛
        couponsType: '', //优惠券类型
        sort: '',
      },
    }
  },
  mounted () { },
  beforeDestroy () {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  methods: {
    // 初始化
    init (id) {
      this.memberId = id
      if (id) {
        this.getDetails(id)
      }
      //配置菜单项
      const editorConfig = { MENU_CONF: {} }
      editorConfig.MENU_CONF['fontSize'] = {
        fontSizeList: ['10px', '11px', '12px', '13px']
      }
      this.editorConfig = editorConfig
      this.isDrawer = true
    },
    onCreated (editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    //关闭弹框
    handleClose () {
      this.isDrawer = false
      const editor = this.editor
      if (editor == null) return
      editor.destroy() // 组件销毁时，及时销毁编辑器
      this.resetForm()
    },
    //获取详情数据
    getDetails (id) {
      this.$http.get(`/member/findMemberPropByMemberId?memberId=${id}`).then(({ data: res }) => {
        this.formOpts.formData = res.data.member
        this.formOpts.formData.json = res.data.prop
      })
    },
    // 触发事件
    handleEvent (type, val) {
      switch (type) {
        case 'checkbox':
          console.log(val, type)
          break
      }
    },
    // 新增商品
    addDomain () {
      this.dialogTitle = '新增'
      let jsonList = this.formOpts.formData.json
      let sort = 0
      if (jsonList.length > 0) {
        sort = jsonList[0].sort + 1
      }
      this.ruleForm = {
        cycle: '7', // 周期,多少天送一次
        giveNum: '4', //赠送的次数
        propImg: '',//道具图片
        propName: '',//道具名称
        propType: '', //道具类型
        num: '', //道具数量
        validDay: '', //有效天数
        money: '', //优惠券面额
        doorSill: '', //使用门槛
        couponsType: '', //优惠券类型
        sort: sort,
      }
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['ruleForm'].clearValidate()
      })
    },
    //编辑
    editList (item, index) {
      this.dialogTitle = '编辑'
      this.ruleForm = item
      this.formIndex = index
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['ruleForm'].clearValidate()
      })
    },
    //删除
    deleteList (item, index) {
      this.formOpts.formData.json.splice(index, 1)
    },
    //保存道具
    affirmPopup () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.butLoading = true
          let ruleForm = this.ruleForm
          if (this.dialogTitle == '新增') {
            this.formOpts.formData.json.push(ruleForm)
          } else {
            if (ruleForm.propType != 4) {
              ruleForm.money = ''
              ruleForm.doorSill = ''
              ruleForm.couponsType = ''
            }
            if (ruleForm.propType == 1) {
              ruleForm.validDay = ''
            }
            this.formOpts.formData.json[this.formIndex] = ruleForm
          }
          this.butLoading = false
          this.dialogFormVisible = false
        }
      })
    },
    //新增 修改
    submitForm () {
      this.formOpts.ref.validate((valid) => {
        if (valid) {
          this.butLoading = true
          let apiUrl = ''
          if (this.memberId) {//修改
            apiUrl = '/member/editMeber'
          } else {//新增
            apiUrl = '/member/addMeber'
          }
          let memberData = JSON.parse(JSON.stringify(this.formOpts.formData))
          if (memberData.json.length > 0) {
            memberData.json = JSON.stringify(memberData.json)
            this.$http.post(apiUrl, memberData).then(res => {
              this.$message({
                message: this.memberId ? '保存成功' : '新增成功',
                type: 'success'
              })
              this.butLoading = false
              this.$emit('refresh')
              this.handleClose()
            }).catch(err => {
              this.butLoading = false
            })
          } else {
            this.butLoading = false
            this.$message({
              message: '请先新增会员道具！',
              type: 'error'
            })
          }
        }
      })
    },
    // 重置清除校验
    resetForm () {
      Object.assign(
        this.$data.formOpts.formData,
        this.$options.data().formOpts.formData
      )
      this.$nextTick(() => {
        this.formOpts.ref.clearValidate()
      })
    },
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scoped>
.agreement_redact {
  padding: 30px;
}
.tableImg {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}
</style>